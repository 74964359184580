import React from 'react';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import { Navigate } from "react-router-dom";


class Logout extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    }
    componentDidMount() {
        this.logout()
    }
    logout = () => {
        const { cookies } = this.props;
        cookies.remove('token');
        console.log('logged out')
    }

    render() {
        return (
            <div>
                <Navigate to="/login" replace={true} />
                <h1>Logging Out....</h1>
            </div>
        )
    };
}

export default withCookies(Logout);