import React from 'react';
import axios from 'axios';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import { Navigate } from "react-router-dom";
import { API_URL } from '../apiURL'
import logo from '../assets/img/logo.svg'



class Slip extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    }
    constructor(props) {
        super(props);
        this.state = {
            notLoggedIn: false,
            bcxviii: [],
            bio: [],
            loadDashboard: false,
            isLoading: true,

        }
    }

    componentDidMount() {
        this.checkCookie();
        this.getBcxviii();
    }
    getBcxviii = () => {
        const { cookies } = this.props;
        axios.get(API_URL + '/api/getbcxviii/' + (cookies.get('token')))
            .then((response) => {
                console.log(response.data)
                if (response.data.length > 0) {
                    this.setState({ bcxviii: response.data[0], bio: response.data[0].user_id[0] })
                }
                else {
                    this.setState({ loadDashboard: true })
                }
                this.setState({ isLoading: false })

            })
            .catch((e) => {
                if (e.response) {
                    console.log(e);
                }
            });
    }

    checkCookie = () => {
        const { cookies } = this.props;
        if (cookies.get('token') === "" || cookies.get('token') === undefined) {
            this.setState({ notLoggedIn: true })
        }
    }

    render() {

        return (
            <div>
                 {this.state.isLoading
                    ?
                    <div id='loader'>
                        <svg height="32" width="32" viewBox="0 0 24 24">
                            <circle opacity=".20" cx="12" cy="12" r="10"></circle><circle cx="12" cy="12" r="10" strokeDasharray="33"></circle>
                        </svg>
                    </div>
                    :
                <section className="dark-section uk-position-relative" data-uk-height-viewport>
                    {this.state.notLoggedIn &&
                        <Navigate to="/login" replace={true} />
                    }
                    {this.state.loadDashboard &&
                        <Navigate to="/dashboard" replace={true} />
                    }
                    <nav className="uk-navbar-container uk-navbar-transparent uk-visible@m" data-uk-navbar>
                        <div className="uk-navbar-left ">
                            <ul className="uk-navbar-nav">
                                <li><a href="/dashboard">
                                    <img className='logo' src={logo} alt='logo' /> <span className='logo-text'>Boothcamp BCXVIII</span>
                                </a></li>
                            </ul>
                        </div>
                        <div className="uk-navbar-right">
                            <ul className="uk-navbar-nav">
                                {!this.state.bcxviii && <li><a href="/dashboard" className="uk-button unpaid-btn  uk-button-large">Unpaid </a></li>}
                                {this.state.bcxviii && <li><a href="/dashboard" className="uk-button paid-btn  uk-button-large">Paid </a></li>}
                                <li><a href="/logout" className="uk-button register-btn  uk-button-large">log out </a></li>
                            </ul>
                        </div>
                    </nav>
                    <div className='uk-hidden@m'>
                        <nav className="uk-navbar-container uk-navbar-transparent " data-uk-navbar>
                            <div className="uk-navbar-left ">
                                <ul className="uk-navbar-nav">
                                    <li><a href="/dashboard">
                                        <img className='logo' src={logo} alt='logo' /> <span className='logo-text'>Boothcamp BCXVIII</span>
                                    </a></li>
                                </ul>
                            </div>
                        </nav>
                        <div className='uk-grid-collapse' data-uk-grid>
                            <div className='uk-width-3-5'>
                                {!this.state.bcxviii && <a href='/dashboard' className="uk-button unpaid-btn uk-width-1-1 uk-button-large">Unpaid </a>}
                                {this.state.bcxviii && <a href="/dashboard" className="uk-button paid-btn uk-width-1-1 uk-button-large">Paid </a>}
                            </div>
                            <div className='uk-width-2-5'>
                                <li><a href="/logout" className="uk-button register-btn uk-width-1-1   uk-button-large">log out </a></li>
                            </div>
                        </div>
                    </div>
                    <section className='dark-section reg-bg'>
                        <div className='uk-container login-container'>
                            <div className='uk-margin-xlarge-top'>
                                <h1 className='reg-header uk-margin-medium '>Hello {this.state.bio.name}  </h1>
                                <h1 className='reg-header uk-margin-medium '>Thank you for Registering for Boothcamp BCXVIII  </h1>
                                <hr />
                                <div data-uk-grid>
                                    <div className='uk-width-1-5@m uk-text-center uk-hidden@m'>
                                        <p className='uk-margin-small-bottom '>Boothcamp ID:</p>
                                        <h1 className='reg-header '>{this.state.bcxviii.boothcamp_id}</h1>
                                        <p className='uk-margin-small-bottom uk-margin-medium-top'>Platoon:</p>
                                        <img src={this.state.bcxviii.platoon ? require(`../assets/img/platoon/${this.state.bcxviii.platoon}.png`) : ""} alt='' />

                                        <h1 className='reg-header uk-margin-remove'>{this.state.bcxviii.platoon} Platoon</h1>
                                    </div>
                                    <div className='uk-width-2-5@m'>
                                        <p className='uk-margin-small-bottom '>Name:</p>
                                        <h1 className='reg-header '>{this.state.bio.name}</h1>
                                        <p className='uk-margin-small-bottom uk-margin-medium-top'>Email:</p>
                                        <h1 className='reg-header '>{this.state.bio.email}</h1>
                                        <p className='uk-margin-small-bottom uk-margin-medium-top'>Phone Number:</p>
                                        <h1 className='reg-header '>{this.state.bio.phone}</h1>
                                        <p className='uk-margin-small-bottom uk-margin-medium-top'>YWAP Center:</p>
                                        <h1 className='reg-header '>{this.state.bio.current_center}</h1>
                                        <p className='uk-margin-small-bottom uk-margin-medium-top'>Date of birth:</p>
                                        <h1 className='reg-header '>{(new Date(this.state.bio.dob)).toDateString()}</h1>
                                        <p className='uk-margin-small-bottom uk-margin-medium-top'>Gender:</p>
                                        <h1 className='reg-header '>{this.state.bio.gender}</h1>


                                    </div>
                                    <div className='uk-width-2-5@m'>
                                        <p className='uk-margin-small-bottom '>Contact Address:</p>
                                        <h1 className='reg-header '>{this.state.bio.address}</h1>
                                        <p className='uk-margin-small-bottom uk-margin-medium-top'>Next of Kin Phone Number:</p>
                                        <h1 className='reg-header '>{this.state.bio.kin_phone}</h1>
                                        <p className='uk-margin-small-bottom uk-margin-medium-top'>Medical Record:</p>
                                        <h1 className='reg-header '>{this.state.bio.medical_record}</h1>
                                        <p className='uk-margin-small-bottom uk-margin-medium-top'>Amount Paid:</p>
                                        <h1 className='reg-header '>₦{this.state.bcxviii.amount}</h1>
                                        <p className='uk-margin-small-bottom uk-margin-medium-top'>Transaction Reference:</p>
                                        <h1 className='reg-header '>{this.state.bcxviii.tx_ref}</h1>


                                    </div>
                                    <div className='uk-width-1-5@m uk-text-center uk-visible@m'>
                                        <p className='uk-margin-small-bottom '>Boothcamp ID:</p>
                                        <h1 className='reg-header '>{this.state.bcxviii.boothcamp_id}</h1>
                                        <p className='uk-margin-small-bottom uk-margin-medium-top'>Platoon:</p>
                                        <img src={this.state.bcxviii.platoon ? require(`../assets/img/platoon/${this.state.bcxviii.platoon}.png`) : ""} alt='' />

                                        <h1 className='reg-header uk-margin-remove'>{this.state.bcxviii.platoon} Platoon</h1>
                                    </div>
                                </div>
                                <div className='uk-margin uk-margin-xlarge-bottom'>
                                    {/* <a href='/slip' className="uk-button register-btn uk-button-large" >Update Profile </a> */}

                                </div>

                            </div>
                        </div>
                    </section>
                    <div className="bottom-bar">
                        <nav className="uk-navbar-container uk-navbar-transparent" data-uk-navbar>
                            <div className="uk-navbar-left">
                                <ul className="uk-navbar-nav footer-nav">
                                    <li><a href="/">© YWAP 2024</a></li>
                                </ul>
                            </div>
                            <div className="uk-navbar-right">
                                <ul className="uk-navbar-nav footer-nav">
                                    <li>
                                        <a href="https://www.instagram.com/ywaphq/" target='_blank' rel="noreferrer">Instagram</a>
                                    </li>
                                    <li>
                                        <a href="https://www.facebook.com/YWAPhq" target='_blank' rel="noreferrer">Facebook</a>
                                    </li>
                                    <li>
                                        <a href="https://www.youtube.com/ywaphq" target='_blank' rel="noreferrer">YouTube</a>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </section>}
            </div>
        )
    }

}
export default withCookies(Slip)
