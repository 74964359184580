import logo from '../assets/img/logo.svg'
import bg from '../assets/img/mountain.svg'
import res1 from '../assets/img/res-1.svg'
// import res2 from '../assets/img/res-2.svg'

function Home() {
  return (
    <section className="dark-section uk-position-relative" data-uk-height-viewport>
      <nav className="uk-navbar-container uk-navbar-transparent uk-position-top" data-uk-navbar>
        <div className="uk-navbar-left">
          <ul className="uk-navbar-nav">
            <li><a href="/">
              <img className='logo' src={logo} alt='logo' /> <span className='logo-text'>Boothcamp BCXVIII</span>
            </a></li>
          </ul>
        </div>
        <div className="uk-navbar-right">
          <ul className="uk-navbar-nav">
            <li><a href="/register" className="uk-button register-btn uk-button-large">register
            </a></li>
          </ul>
        </div>
      </nav>
      <div className="uk-flex uk-flex-center uk-flex-middle uk-background-cover uk-light" data-src={bg} data-uk-height-viewport data-uk-img="loading: eager">
        <div className=' uk-container uk-text-center'>
          <h1 className='header-title uk-hidden@s'>BoothCamp <br /><span style={{ color: '#E89B0D' }}>'24</span></h1>
          <h1 className='header-title uk-visible@s'>BoothCamp <span style={{ color: '#E89B0D' }}>'24</span></h1>
          <img src={res1} alt='' className='badge'/>
          {/* <img src={res2} alt='' className='badge'/> */}
          {/* <div data-uk-lightbox>
            <a className="uk-button play-btn" href="https://www.youtube.com/watch?v=4v9IcxX74b0" data-caption="Boothcamp BCXVII (2022) Official Announcement Video.">play video  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="8" cy="8" r="8" fill="#E89B0D" />
                <path d="M4.9231 5.88084C4.9231 5.14848 5.68441 4.66454 6.34753 4.97538L10.4954 6.91968C11.2418 7.26956 11.2681 8.32152 10.5401 8.70826L6.39225 10.9118C5.7262 11.2656 4.9231 10.7829 4.9231 10.0287V5.88084Z" fill="#0F0F0F" />
              </svg>


            </a>
          </div> */}
          <p className='header-subtitle uk-width-2-3@s uk-margin-auto'>A week long summer camp that convenes over a thousand members and friends from across Nigeria</p>
          {/* <svg width="36" height="19" viewBox="0 0 36 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="0.353553" y1="0.646447" x2="18.3536" y2="18.6464" stroke="#E89B0D" />
            <line x1="17.6464" y1="18.6464" x2="35.6464" y2="0.646446" stroke="#E89B0D" />
          </svg> */}
          {/* <svg width="36" height="20" viewBox="0 0 36 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="0.707107" y1="1.29289" x2="18.7071" y2="19.2929" stroke="#E89B0D" stroke-width="2" />
            <line x1="17.2929" y1="19.2929" x2="35.2929" y2="1.29289" stroke="#E89B0D" stroke-width="2" />
          </svg> */}

          <div className='uk-margin-large-top' data-uk-grid>
            <div className='uk-width-1-2@m'>
              <h1 className="reg-header " >Already Registered? <a style={{ color: '#E89B0D' }} href='/login'>Log in
              </a></h1>
            </div>
            <div className='uk-width-1-2@m'>
              <h1 className="reg-header" > <a style={{ color: '#E89B0D' }} href='https://giving.ywap.online' target='blank_'>Donate <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.08125 0.368432L1.58125 0.368454L1.5813 3.36845L3.0813 3.36843L3.08125 0.368432ZM14.3951 1.86827L15.8951 1.86825L15.8951 0.368247L14.3951 0.368269L14.3951 1.86827ZM12.8952 13.1819L12.8953 14.6819L15.8953 14.6818L15.8952 13.1818L12.8952 13.1819ZM2.02073 12.1213L0.960071 13.182L3.08139 15.3033L4.14205 14.2426L2.02073 12.1213ZM3.0813 3.36843L14.3951 3.36827L14.3951 0.368269L3.08125 0.368432L3.0813 3.36843ZM12.8951 1.86829L12.8952 13.1819L15.8952 13.1818L15.8951 1.86825L12.8951 1.86829ZM13.3344 0.807609L2.02073 12.1213L4.14205 14.2426L15.4558 2.92893L13.3344 0.807609Z" fill="#E89B0D" fill-opacity="0.9" />
              </svg>
              </a></h1>
            </div>

          </div>
        </div>
      </div>

      <div className="uk-position-bottom bottom-bar">
        <nav className="uk-navbar-container uk-navbar-transparent" data-uk-navbar>
          <div className="uk-navbar-left">
            <ul className="uk-navbar-nav footer-nav">
              <li><a href="/">© YWAP 2024</a></li>
            </ul>
          </div>
          <div className="uk-navbar-right">
            <ul className="uk-navbar-nav footer-nav">
              <li>
                <a href="https://www.instagram.com/ywaphq/" target='_blank' rel="noreferrer">Instagram</a>
              </li>
              <li>
                <a href="https://www.facebook.com/YWAPhq" target='_blank' rel="noreferrer">Facebook</a>
              </li>
              <li>
                <a href="https://www.youtube.com/ywaphq" target='_blank' rel="noreferrer">YouTube</a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </section >
  );
}

export default Home;
