import React from 'react';
import axios from 'axios';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import { Navigate } from "react-router-dom";
import { API_URL } from '../apiURL'
import logo from '../assets/img/logo.svg'



class Badge extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    }
    constructor(props) {
        super(props);
        this.state = {
            notLoggedIn: false,
            bcxviii: [],
            bio: [],
            loadDashboard: false

        }
    }

    componentDidMount() {
        this.checkCookie();
        this.getBcxviii();
    }
    getBcxviii = () => {
        const { cookies } = this.props;
        axios.get(API_URL + '/api/getbcxviii/' + (cookies.get('token')))
            .then((response) => {
                console.log(response.data)
                if (response.data.length > 0) {
                    this.setState({ bcxviii: response.data[0], bio: response.data[0].user_id[0] })
                }
                else {
                    this.setState({ loadDashboard: true })
                }
            })
            .catch((e) => {
                if (e.response) {
                    console.log(e);
                }
            });
    }

    checkCookie = () => {
        const { cookies } = this.props;
        if (cookies.get('token') === "" || cookies.get('token') === undefined) {
            this.setState({ notLoggedIn: true })
        }
    }

    render() {

        return (
            <section className="dark-section uk-position-relative" data-uk-height-viewport>
                {this.state.notLoggedIn &&
                    <Navigate to="/login" replace={true} />
                }
                {this.state.loadDashboard &&
                    <Navigate to="/dashboard" replace={true} />
                }
                <nav className="uk-navbar-container uk-navbar-transparent uk-visible@m" data-uk-navbar>
                    <div className="uk-navbar-left ">
                        <ul className="uk-navbar-nav">
                            <li><a href="/dashboard">
                                <img className='logo' src={logo} alt='logo' /> <span className='logo-text'>Boothcamp BCXVIII</span>
                            </a></li>
                        </ul>
                    </div>
                    <div className="uk-navbar-right">
                        <ul className="uk-navbar-nav">
                            {!this.state.bcxviii && <li><a href="/dashboard" className="uk-button unpaid-btn  uk-button-large">Unpaid </a></li>}
                            {this.state.bcxviii && <li><a href="/dashboard" className="uk-button paid-btn  uk-button-large">Paid </a></li>}
                            <li><a href="/logout" className="uk-button register-btn  uk-button-large">log out </a></li>
                        </ul>
                    </div>
                </nav>
                <div className='uk-hidden@m'>
                    <nav className="uk-navbar-container uk-navbar-transparent " data-uk-navbar>
                        <div className="uk-navbar-left ">
                            <ul className="uk-navbar-nav">
                                <li><a href="/dashboard">
                                    <img className='logo' src={logo} alt='logo' /> <span className='logo-text'>Boothcamp BCXVIII</span>
                                </a></li>
                            </ul>
                        </div>
                    </nav>
                    <div className='uk-grid-collapse' data-uk-grid>
                        <div className='uk-width-3-5'>
                            {!this.state.bcxviii && <a href='/dashboard' className="uk-button unpaid-btn uk-width-1-1 uk-button-large">Unpaid </a>}
                            {this.state.bcxviii && <a href="/dashboard" className="uk-button paid-btn uk-width-1-1 uk-button-large">Paid </a>}
                        </div>
                        <div className='uk-width-2-5'>
                            <li><a href="/logout" className="uk-button register-btn uk-width-1-1   uk-button-large">log out </a></li>
                        </div>
                    </div>
                </div>
                <section className='dark-section reg-bg'>
                    <div className='uk-container login-container'>
                        <div className='uk-margin-xlarge-top uk-margin-xlarge-bottom'>
                            <h1 className='reg-header uk-margin-medium '>Hello {this.state.bio.name}  </h1>
                            <h1 className='reg-header uk-margin-medium '>Get Your Badges Here  </h1>
                            <hr />
                            <h1 className='reg-header'>Take Screenshots of the Badges, Crop Them along the borders and Share on your socials and with friends</h1>
                            <h2 className='uk-margin-medium' style={{ color: '#E89B0D' }}>Lets Make Boothcamp BCXVIII Loud Everywhere!!!!!</h2>

                            <div className="uk-text-center uk-grid-medium uk-grid-match" data-uk-grid>
                                <div className="uk-width-1-3@m ">
                                    <div className='square uk-text-center uk-padding' >
                                        <h1 className='reg-header'>{this.state.bio.name} </h1>
                                        <hr />
                                        {this.state.bcxviii.platoon && <img className='' src={require(`../assets/img/platoon/${this.state.bcxviii.platoon}.png`)} alt='' />}
                                        <h4 className='team-bg uk-margin'>#Team{this.state.bcxviii.platoon}</h4>
                                        <p className='badge-footer-text' >YWAP BOOTHCAMP BCXVIII 2024</p>

                                    </div>
                                </div>
                                <div className="uk-width-1-3@m ">
                                    <div className='square uk-text-center uk-padding' >
                                        {this.state.bcxviii.platoon && <img className='' src={require(`../assets/img/platoon/${this.state.bcxviii.platoon}.png`)} alt='' />}
                                        <h1 className='reg-header'>{this.state.bio.name} </h1>
                                        <h4 className='team-bg uk-margin'>{this.state.bcxviii.platoon} Platoon</h4>
                                        <p className='badge-footer-text' >YWAP BOOTHCAMP BCXVIII 2024</p>

                                    </div>
                                </div>
                                <div className="uk-width-1-3@m ">
                                    <div className='square uk-text-center uk-padding' >
                                        {this.state.bcxviii.platoon && <img className='' src={require(`../assets/img/platoon/${this.state.bcxviii.platoon}.png`)} alt='' />}
                                        <h1 className='reg-header'>{this.state.bio.name} </h1>
                                        <h4 className='team-bg uk-margin'>{this.state.bcxviii.boothcamp_id}</h4>
                                        <p className='badge-footer-text' >YWAP BOOTHCAMP BCXVIII 2024</p>

                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </section>
                <div className="bottom-bar">
                    <nav className="uk-navbar-container uk-navbar-transparent" data-uk-navbar>
                        <div className="uk-navbar-left">
                            <ul className="uk-navbar-nav footer-nav">
                                <li><a href="/">© YWAP 2023</a></li>
                            </ul>
                        </div>
                        <div className="uk-navbar-right">
                            <ul className="uk-navbar-nav footer-nav">
                                <li>
                                    <a href="https://www.instagram.com/ywaphq/" target='_blank' rel="noreferrer">Instagram</a>
                                </li>
                                <li>
                                    <a href="https://www.facebook.com/YWAPhq" target='_blank' rel="noreferrer">Facebook</a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/ywaphq" target='_blank' rel="noreferrer">YouTube</a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </section>
        )
    }

}
export default withCookies(Badge)
