import React from 'react';
import axios from 'axios';
import { Navigate } from "react-router-dom";
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import { API_URL } from '../../apiURL'
import logo from '../../assets/img/logo.svg'


class Admin extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    }
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            notLoggedIn: false,
            notAdmin: false,
            bio: [],
            totalCamper: 0,
            totalRegisteredOnline: 0,
            totalRegisteredOffline: '',
            bcxviii_online: [],
            bcxviii_offline: [], 
            totalCheckedin: 0

        }
    }
    componentDidMount() {
        this.setState({ isLoading: false })
        this.checkCookie();
        this.checkAdmin();
        this.getbcxviii_online()
        this.getbcxviii_offline()
        this.getBio()
        this.getCheckedin()
    }

    checkCookie = () => {
        const { cookies } = this.props;
        if (cookies.get('token') === "" || cookies.get('token') === undefined) {
            this.setState({ notLoggedIn: true })
        }
    }

    checkAdmin = () => {
        const { cookies } = this.props;
        axios.get(API_URL + '/api/getuser/' + (cookies.get('token')))
            .then((response) => {
                if (response.data[0].user_type === 1) {
                    this.setState({ notAdmin: false })
                }
                else {
                    this.setState({ notAdmin: true })
                }
            })
            .catch((e) => {
                if (e.response) {
                    console.log(e);
                }
            });
    }

    getbcxviii_online = () => {
        axios.get(API_URL + '/api/getbcxviiionline/')
            .then((response) => {
                this.setState({ bcxviii_online: response.data, totalRegisteredOnline: response.data.length, isLoading: false })
                // console.log(response)
            })
            .catch((e) => {
                if (e.response) {
                    console.log(e);
                }
            });
    }

    getbcxviii_offline = () => {
        axios.get(API_URL + '/api/getbcxviiioffline/')
            .then((response) => {
                this.setState({ bcxviii_offline: response.data, totalRegisteredOffline: response.data.length, isLoading: false })
                // console.log(response)
            })
            .catch((e) => {
                if (e.response) {
                    console.log(e);
                }
            });
    }

    getBio = () => {
        axios.get(API_URL + '/api/bio/')
            .then((response) => {
                this.setState({ bio: response.data, totalCamper: response.data.length, isLoading: false })
            })
            .catch((e) => {
                if (e.response) {
                    console.log(e);
                }
            });
    }

    getCheckedin = () => {
        axios.get(API_URL + '/api/getcheckedin/')
            .then((response) => {
                this.setState({totalCheckedin: response.data.length, isLoading: false })
            })
            .catch((e) => {
                if (e.response) {
                    console.log(e);
                }
            });
    }

    computeTotal = (arr, totalFieldName) => {
        const { length } = arr;
        let count = 0;
        var amount
        for (let i = 0; i < length; i += 1) {
            if (arr[i][totalFieldName] === '15750') {
                amount = 15000
            }
            if (arr[i][totalFieldName] === '10500') {
                amount = 10000
            }
            count += amount;
        }
        return count;
    };


    computeTotalOffline = (arr, totalFieldName) => {
        const { length } = arr;
        let count = 0;
        for (let i = 0; i < length; i += 1) {
            count += Number((typeof arr[i][totalFieldName] === 'object')
                ? arr[i][totalFieldName].name : arr[i][totalFieldName] || 0);
        }
        return count;
    };



    render() {
        return (
            <div>
                {this.state.isLoading
                    ?
                    <div id='loader'>
                        <svg height="32" width="32" viewBox="0 0 24 24">
                            <circle opacity=".20" cx="12" cy="12" r="10"></circle><circle cx="12" cy="12" r="10" strokeDasharray="33"></circle>
                        </svg>
                    </div>
                    :
                    <div>
                        {this.state.notLoggedIn &&
                            <Navigate to="/login" replace={true} />
                        }
                        {this.state.notAdmin &&
                            <Navigate to="/dashboard" replace={true} />
                        }
                        <section className="dark-section uk-position-relative" data-uk-height-viewport>
                            <nav className="uk-navbar-container uk-navbar-transparent" data-uk-navbar>
                                <div className="uk-navbar-left ">
                                    <ul className="uk-navbar-nav">
                                        <li><a href="/admin">
                                            <img className='logo' src={logo} alt='logo' /> <span className='logo-text'>Boothcamp BCXVII</span>
                                        </a></li>
                                    </ul>
                                </div>
                                <div className="uk-navbar-right">
                                    <ul className="uk-navbar-nav">
                                        <li><a href="/admin/stats" className="uk-button register-btn  uk-button-large">View Online Stats </a></li>
                                    </ul>
                                </div>
                            </nav>
                            <section className='dark-section reg-bg'>
                                <div className='uk-container login-container'>
                                    <div className='uk-margin-xlarge-top'>
                                        {/* <h1 className='reg-header uk-margin-medium '>Boothcamp BCXVII Stats </h1> */}
                                        <div data-uk-grid>
                                            <div className='uk-width-1-4@m'>
                                                <div className="stats-card">
                                                    <p>Total Accounts Created</p>
                                                    <h1>{this.state.totalCamper}</h1>
                                                </div>
                                            </div>
                                            <div className='uk-width-1-4@m'>
                                                <div className="stats-card">
                                                    <p>Total Online Registrations</p>
                                                    <h1>{this.state.totalRegisteredOnline}</h1>
                                                </div>
                                            </div>
                                            <div className='uk-width-1-4@m'>
                                                <div className="stats-card">
                                                    <p>Total Offline Registrations</p>
                                                    <h1>{this.state.totalRegisteredOffline}</h1>
                                                </div>
                                            </div>
                                            <div className='uk-width-1-4@m'>
                                                <div className="stats-card">
                                                    <p>Total Campers Checked In </p>
                                                    <h1>{this.state.totalCheckedin}</h1>
                                                </div>
                                            </div>
                                        </div>
                                        <div data-uk-grid>
                                            <div className='uk-width-1-2@m'>
                                                <h1 className='reg-header uk-margin-medium '>Total Online Amount<br/> <span style={{ color: '#E89B0D' }}>₦{this.computeTotal(this.state.bcxviii_online, "amount").toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}  </span></h1>
                                            </div>
                                            <div className='uk-width-1-2@m'>
                                                <h1 className='reg-header uk-margin-medium '>Total Offline Amount <br/> <span style={{ color: '#E89B0D' }}>₦{this.computeTotalOffline(this.state.bcxviii_offline, "amount").toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}  </span></h1>
                                            </div>
                                        </div>
                                        <hr />
                                        <h1 className='reg-header uk-margin-medium '>Boothcamp Admin Tools</h1>
                                        <div data-uk-grid>
                                            <div className='uk-width-1-3@m'>
                                                <a style={{ textDecoration: 'none' }} href='/admin/check-in'>
                                                    <div className="stats-card">
                                                        <h3>Check in Registered Campers</h3>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className='uk-width-1-3@m'>
                                                <a style={{ textDecoration: 'none' }} href='/admin/check-in-offline'>
                                                    <div className="stats-card">
                                                        <h3>Register Campers <br/>Offline</h3>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className='uk-width-1-3@m'>
                                                <a style={{ textDecoration: 'none' }} href='/admin/check-in-stats'>
                                                    <div className="stats-card">
                                                        <h3>View Checked in <br/>Campers</h3>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </section>
                    </div>
                }
            </div>
        )
    }

}

export default withCookies(Admin)
