import logo from '../assets/img/logo.svg'
import bg from '../assets/img/mountain.svg'

function Question() {
  return (
    <section className="dark-section uk-position-relative" data-uk-height-viewport>
      <nav className="uk-navbar-container uk-navbar-transparent uk-position-top" data-uk-navbar>
        <div className="uk-navbar-left">
          <ul className="uk-navbar-nav">
            <li><a href="/">
              <img className='logo' src={logo} alt='logo' /> <span className='logo-text'>Boothcamp BCXVII</span>
            </a></li>
          </ul>
        </div>

      </nav>
      <div className="uk-flex uk-flex-center uk-flex-middle uk-background-cover uk-light" data-src={bg} data-uk-height-viewport data-uk-img="loading: eager">
        <div className=' uk-container uk-text-center '>
          <h1 className='question-title'>Did you attend Boothcamp Last Year?</h1>
          <div data-uk-grid>
            <div className='uk-width-1-2'>
              <a href="/login" className="uk-button uk-button-large qtn-btn">Yes</a>
            </div>
            <div className='uk-width-1-2'>
              <a href="/register" className="uk-button uk-button-large qtn-btn">No</a>
            </div>
          </div>

          <div></div>
        </div>
      </div>

      <div className="uk-position-bottom bottom-bar">
        <nav className="uk-navbar-container uk-navbar-transparent" data-uk-navbar>
          <div className="uk-navbar-left">
            <ul className="uk-navbar-nav footer-nav">
              <li><a href="/">© YWAP 2023</a></li>
            </ul>
          </div>
          <div className="uk-navbar-right">
            <ul className="uk-navbar-nav footer-nav">
              <li>
                <a href="https://www.instagram.com/ywaphq/" target='_blank' rel="noreferrer">Instagram</a>
              </li>
              <li>
                <a href="https://www.facebook.com/YWAPhq" target='_blank' rel="noreferrer">Facebook</a>
              </li>
              <li>
                <a href="https://www.youtube.com/ywaphq" target='_blank' rel="noreferrer">YouTube</a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </section >
  );
}

export default Question;
