import React from 'react';
import axios from 'axios';
import { FlutterWaveButton, closePaymentModal } from 'flutterwave-react-v3';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import { Navigate } from "react-router-dom";
import { API_URL } from '../apiURL'
import logo from '../assets/img/logo.svg'

class ManualPayment extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    }
    constructor(props) {
        super(props);
        this.state = {
            notLoggedIn: false,
            bio: [],
            isProcessing: false,
            bcxvii: [],
            loadDonate: false,
            isLoading: true,

        }
    }
    componentDidMount() {
        this.checkCookie();
        this.getBio();
        this.getBcxvii();
    }

    inputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    checkCookie = () => {
        const { cookies } = this.props;
        if (cookies.get('token') === "" || cookies.get('token') === undefined) {
            this.setState({ notLoggedIn: true })
        }
    }

    getBio = () => {
        const { cookies } = this.props;
        axios.get(API_URL + '/api/bio/' + (cookies.get('token')))
            .then((response) => {
                this.setState({ bio: response.data })
            })
            .catch((e) => {
                if (e.response) {
                    console.log(e);
                }
            });
    }

    getBcxvii = () => {
        const { cookies } = this.props;
        axios.get(API_URL + '/api/getbcxvii/' + (cookies.get('token')))
            .then((response) => {
                this.setState({ bcxvii: response.data[0], isLoading: false })

            })
            .catch((e) => {
                if (e.response) {
                    console.log(e);
                }
            });
    }

    verifyPayment = (resp) => {
        axios.post(API_URL + '/api/verifymanualpayment/', resp)
            .then((response) => {
                console.log(response)
                if (response.data.status === 1) {
                    this.setState({ loadDonate: true, isProcessing: false })
                }
            })
            .catch((e) => {
                if (e.response) {
                    console.log(e);
                }
            });

    }



    render() {
        const config = {
            public_key: 'FLWPUBK-458a89466adca3bc3400e1483675ced5-X',
            tx_ref: Date.now(),
            amount: 3150,
            currency: 'NGN',
            subaccounts: [
                {
                    id: "RS_9BB18F69AE168DFB4CC2B3C53C6EB639",
                    transaction_charge_type: "flat_subaccount",
                    transaction_charge: 3000,
                }
            ],
            payment_options: 'card,ussd',
            customer: {
                email: this.state.bio.email,
                phonenumber: this.state.bio.phone,
                name: this.state.bio.name,
            },
            meta: {
                user_id: this.state.bio._id
            },
            customizations: {
                title: "YWAP Boothcamp BCXVII Registration",
                description: "Payment for 1 x Boothcamp BCXVII '23 Registration",
                logo: "https://res.cloudinary.com/isabicontest/image/upload/v1652659707/YWAP/bc_logo_reux7u.svg",
            },
        };

        const fwConfig = {
            ...config,
            text: 'Pay ₦3,150 Now',
            callback: (response) => {
                this.setState({ isProcessing: true })
                closePaymentModal()
                console.log(response)
                this.verifyPayment(response)
            },
            onClose: (incomplete) => {
                // console.log('Close: ', incomplete)
            },
        };

        return (
            <div>
                {this.state.isLoading
                    ?
                    <div id='loader'>
                        <svg height="32" width="32" viewBox="0 0 24 24">
                            <circle opacity=".20" cx="12" cy="12" r="10"></circle><circle cx="12" cy="12" r="10" strokeDasharray="33"></circle>
                        </svg>
                    </div>
                    :
                    <section className="dark-section uk-position-relative" data-uk-height-viewport>
                        {this.state.notLoggedIn &&
                            <Navigate to="/login" replace={true} />
                        }
                        {this.state.loadDonate &&
                            <Navigate to="/reg-donate" replace={true} />
                        }
                        <nav className="uk-navbar-container uk-navbar-transparent uk-visible@m" data-uk-navbar>
                            <div className="uk-navbar-left ">
                                <ul className="uk-navbar-nav">
                                    <li><a href="/dashboard">
                                        <img className='logo' src={logo} alt='logo' /> <span className='logo-text'>Boothcamp BCXVII</span>
                                    </a></li>
                                </ul>
                            </div>
                            <div className="uk-navbar-right">
                                <ul className="uk-navbar-nav">
                                    {!this.state.bcxvii && <li><a href="/dashboard" className="uk-button unpaid-btn  uk-button-large">Unpaid </a></li>}
                                    {this.state.bcxvii && <li><a href="/dashboard" className="uk-button paid-btn  uk-button-large">Paid </a></li>}
                                    <li><a href="/logout" className="uk-button register-btn  uk-button-large">log out </a></li>
                                </ul>
                            </div>
                        </nav>
                        <div className='uk-hidden@m'>
                            <nav className="uk-navbar-container uk-navbar-transparent " data-uk-navbar>
                                <div className="uk-navbar-left ">
                                    <ul className="uk-navbar-nav">
                                        <li><a href="/dashboard">
                                            <img className='logo' src={logo} alt='logo' /> <span className='logo-text'>Boothcamp BCXVII</span>
                                        </a></li>
                                    </ul>
                                </div>
                            </nav>
                            <div className='uk-grid-collapse' data-uk-grid>
                                <div className='uk-width-3-5'>
                                    {!this.state.bcxvii && <a href='/dashboard' className="uk-button unpaid-btn uk-width-1-1 uk-button-large">Unpaid </a>}
                                    {this.state.bcxvii && <a href="/dashboard" className="uk-button paid-btn uk-width-1-1 uk-button-large">Paid </a>}
                                </div>
                                <div className='uk-width-2-5'>
                                    <li><a href="/logout" className="uk-button register-btn uk-width-1-1   uk-button-large">log out </a></li>
                                </div>
                            </div>
                        </div>
                        <section className='dark-section reg-bg'>
                            <div className='uk-container login-container'>
                                {this.state.isProcessing &&
                                    <div className='uk-margin-xlarge-top' style={{ minHeight: '70vh' }}>
                                        <h1 className="reg-header uk-margin-top uk-margin-bottom" style={{ color: '#E89B0D' }}>Payment Processing</h1>
                                        <p>Your Payment and Registration for Boothcamp BCXVII is been Processed</p>
                                    </div>
                                }
                                {(!this.state.bcxvii && !this.state.isProcessing) &&
                                    <div className='uk-margin-xlarge-top'>
                                        <h1 className='reg-header uk-margin-medium'>Hello {this.state.bio.name} ,</h1>
                                        <h1 className='reg-header uk-margin-medium '>Proceed to make a balance payment</h1>
                                        <div className='fee-bg uk-grid-collapse uk-padding' data-uk-grid>
                                            <div className='uk-width-2-3'>
                                                <p>1 x Boothcamp BCXVII '23 Registration - Balance</p>
                                                <p>fees</p>
                                            </div>
                                            <div className='uk-width-1-3'>
                                                <p>₦3,000</p>
                                                <p>₦150</p>
                                            </div>
                                            <div className='uk-width-1-1 uk-padding-small uk-padding-remove-horizontal'>
                                                <hr />
                                            </div>
                                            <div className='uk-width-2-3'>
                                                <h3>Total</h3>
                                            </div>
                                            <div className='uk-width-1-3'>
                                                <h3>₦3,150</h3>
                                            </div>
                                        </div>
                                        <div className='uk-margin uk-margin-xlarge-bottom'>
                                            <FlutterWaveButton className="uk-button pay-btn  uk-button-large uk-width-1-2@m" {...fwConfig} />
                                        </div>
                                    </div>
                                }
                                {this.state.bcxvii &&
                                    <div className='uk-margin-xlarge-top'>
                                        <div data-uk-grid>
                                            <div className='uk-width-1-5@m uk-text-center'>
                                                <h4 className='team-bg uk-margin'>#Team{this.state.bcxvii.platoon}</h4>

                                                <img src={this.state.bcxvii.platoon ? require(`../assets/img/platoon/${this.state.bcxvii.platoon}.png`) : ""} alt='' width='150' />

                                                <h1 className='reg-header '>{this.state.bcxvii.boothcamp_id}</h1>
                                                <a style={{ color: '#E89B0D' }} href='/slip'>View BoothCamp Slip</a>


                                            </div>
                                            <div className='uk-width-4-5@m'>
                                                {/* <h1 className='dashboard-big-text uk-margin'>Hello {this.state.bio.name} </h1> */}
                                                <h1 className='reg-header uk-margin-medium'>Hello {this.state.bio.name} ,</h1>
                                                <h1 className='reg-header uk-margin-medium '>We can’t wait to have you join us at Boothcamp BCXVII.<br /><br />
                                                    Here are a few things you can do between now and Boothcamp to get ready.</h1>
                                                <div className='orange-bg uk-padding uk-margin'>
                                                    <h3 >Invite Others to register</h3>
                                                    <p className='uk-margin-remove'>Spread the Word to Friends and Family both online and offline that Boothcamp Registration is on!</p>
                                                </div>
                                                <div className='blue-bg uk-padding'>
                                                    <a href='/badge'>
                                                        <h3 style={{ color: '#FFFFFF' }}>Share your Boothcamp Badge across Social Media</h3>
                                                        <p style={{ color: '#FFFFFF' }} className='uk-margin-remove'>Click Here to Generate your BoothCamp Badge</p>
                                                    </a>
                                                </div>

                                                <h4 style={{ color: '#E89B0D' }} className=' uk-margin-medium '>* This Dashboard will be updated in due time</h4>

                                            </div>
                                        </div>
                                        <div className='uk-margin uk-margin-xlarge-bottom'>
                                        </div>
                                    </div>
                                }
                            </div>
                        </section>

                        <div className="bottom-bar">
                            <nav className="uk-navbar-container uk-navbar-transparent" data-uk-navbar>
                                <div className="uk-navbar-left">
                                    <ul className="uk-navbar-nav footer-nav">
                                        <li><a href="/">© YWAP 2023</a></li>
                                    </ul>
                                </div>
                                <div className="uk-navbar-right">
                                    <ul className="uk-navbar-nav footer-nav">
                                        <li>
                                            <a href="https://www.instagram.com/ywaphq/" target='_blank' rel="noreferrer">Instagram</a>
                                        </li>
                                        <li>
                                            <a href="https://www.facebook.com/YWAPhq" target='_blank' rel="noreferrer">Facebook</a>
                                        </li>
                                        <li>
                                            <a href="https://www.youtube.com/ywaphq" target='_blank' rel="noreferrer">YouTube</a>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </section >}
            </div>
        )
    }
}
export default withCookies(ManualPayment)
