import React from 'react';
import axios from 'axios';
import { Navigate } from "react-router-dom";
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { API_URL } from '../apiURL'
import logo from '../assets/img/logo.svg'
import bg from '../assets/img/mountain.svg'

class Login extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    }

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            isProcessing: false,
            loginError: false,
            loggedin: false
        }
    }

    inputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    submitLogin = (e) => {
        e.preventDefault();
        this.setState({ isProcessing: true })
        const payload = {
            email: this.state.email,
            password: this.state.password
        }
        const { cookies } = this.props;


        axios.post(API_URL + '/api/login/', payload)
            .then((response) => {
                if (response.status === 204 && response.data === '') {
                    this.setState({
                        loginError: true,
                        isProcessing: false,
                    })
                }
                else {
                    cookies.set('token', response.data[0].user_id, { path: '/' });
                    this.setState({ loginError: false, loggedin: true })
                }
            })
            .catch((e) => {
                if (e.response) {
                    console.log(e);
                }
            });
    }

    render() {
        return (
            <section className="dark-section uk-position-relative" data-uk-height-viewport>
                {this.state.loggedin &&
                    <Navigate to="/dashboard" replace={true} />
                }
                <nav className="uk-navbar-container uk-navbar-transparent uk-position-top" data-uk-navbar>
                    <div className="uk-navbar-left">
                        <ul className="uk-navbar-nav">
                            <li><a href="/">
                                <img className='logo' src={logo} alt='logo' /> <span className='logo-text'>Boothcamp BCXVIII</span>
                            </a></li>
                        </ul>
                    </div>

                </nav>
                <div className="uk-flex uk-flex-center uk-flex-middle uk-background-cover" data-src={bg} data-uk-height-viewport data-uk-img="loading: eager">
                    <div className='uk-text-left uk-width-2-5@m'>
                        {this.state.loginError && <div className="alert" data-uk-alert>
                            <button className="uk-alert-close" data-uk-close></button>
                            <p>Incorrect Username or Password</p>
                        </div>}
                        <div className='login-container uk-container-xlarge uk-padding-large'>
                            <h1 className='reg-header'>Log in </h1>
                            <form method='POST' onSubmit={this.submitLogin} className="uk-margin-top" data-uk-grid>
                                <div className=" uk-width-1-1@m">
                                    <label className="form-label">Email Address</label>
                                    <input name="email" className="uk-input uk-form-large uk-margin-small-top" type="email" placeholder="enter your email" onChange={this.inputChange} required />
                                </div>
                                <div className=" uk-width-1-1@m">
                                    <label className="form-label">Password</label>
                                    <input name="password" className="uk-input uk-form-large uk-margin-small-top" type="password" placeholder="• • • • • • • •" onChange={this.inputChange} required />
                                </div>
                                <div className='uk-width-1-1@m uk-flex uk-flex-center uk-flex-middle' data-uk-grid>
                                    <div className='uk-width-1-2@l'>
                                        <button type='submit' className="uk-button register-btn uk-button-large" >
                                            {this.state.isProcessing
                                                ? <div id='btnloader' className='uk-inline '>
                                                    <svg height="24" width="24" viewBox="0 0 24 24">
                                                        <circle opacity=".20" cx="12" cy="12" r="10"></circle><circle cx="12" cy="12" r="10" strokeDasharray="33"></circle>
                                                    </svg>

                                                </div>
                                                : 'log in'}
                                        </button>
                                    </div>
                                    <div className='uk-width-1-2@l'>
                                        <a className='forgot-text' href='/forgot-password' >Forgot Password?</a>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="uk-position-bottom bottom-bar">
                    <nav className="uk-navbar-container uk-navbar-transparent" data-uk-navbar>
                        <div className="uk-navbar-left">
                            <ul className="uk-navbar-nav footer-nav">
                                <li><a href="/">© YWAP 2024</a></li>
                            </ul>
                        </div>
                        <div className="uk-navbar-right">
                            <ul className="uk-navbar-nav footer-nav">
                                <li>
                                    <a href="https://www.instagram.com/ywaphq/" target='_blank' rel="noreferrer">Instagram</a>
                                </li>
                                <li>
                                    <a href="https://www.facebook.com/YWAPhq" target='_blank' rel="noreferrer">Facebook</a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/ywaphq" target='_blank' rel="noreferrer">YouTube</a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </section >
        )
    }
}

export default withCookies(Login)
