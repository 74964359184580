import React from 'react';
import axios from 'axios';
import { API_URL } from '../apiURL'
import logo from '../assets/img/logo.svg'
import bg from '../assets/img/mountain.svg'

class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            isProcessing: false,
            isCompleted: false,
            resetError: false
        }
    }

    inputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    resetPassword = (e) => {
        e.preventDefault();
        this.setState({ isProcessing: true })

        axios.get(API_URL + '/api/getpassword/' + this.state.email)
            .then((response) => {
                if (response.status === 204 && response.data === '') {
                    this.setState({ resetError: true, isProcessing: false, })
                }
                else {
                    this.setState({ resetError: false, isCompleted: true })
                }
            })
            .catch((e) => {
                if (e.response) {
                    console.log(e);
                }
            });
    }

    render() {
        return (
            <section className="dark-section uk-position-relative" data-uk-height-viewport>
                <nav className="uk-navbar-container uk-navbar-transparent uk-position-top" data-uk-navbar>
                    <div className="uk-navbar-left">
                        <ul className="uk-navbar-nav">
                            <li><a href="/">
                                <img className='logo' src={logo} alt='logo' /> <span className='logo-text'>Boothcamp BCXVIII</span>
                            </a></li>
                        </ul>
                    </div>
                </nav>
                <div className="uk-flex uk-flex-center uk-flex-middle uk-background-cover" data-src={bg} data-uk-height-viewport data-uk-img="loading: eager">

                    <div className='uk-text-left uk-width-2-5@m'>

                        <div className='login-container uk-container-xlarge uk-padding-large'>
                            {this.state.isCompleted
                                ?
                                <div>
                                    <h1 className="reg-header uk-margin-top uk-margin-bottom">You've got mail</h1>
                                    <p>We've sent an email to the email: <span style={{ color: '#E89B0D' }}>{this.state.email}</span>  with a link to
                                        reset your password</p>
                                    <p className='uk-text-meta uk-margin-large-top' style={{ color: '#FFFFFF' }}>Didn't get our email?</p>
                                    <p>The email might be in your spam folder. Kindly check and
                                        click on the link to reset your password</p>
                                </div>
                                :
                                <div>
                                    <h1 className='reg-header'>Forgot Password? </h1>
                                    <p>Please enter your email address and if its valid, we'll send you a link to reset your password.</p>
                                    <form method='POST' onSubmit={this.resetPassword} className="uk-margin-top" data-uk-grid>
                                        <div className=" uk-width-1-1@m uk-margin-top">
                                            <label className="form-label">Email Address</label>
                                            <input name="email" className="uk-input uk-form-large uk-margin-small-top" type="email" placeholder="enter your email" onChange={this.inputChange} required />
                                        </div>
                                        {this.state.resetError && <p style={{ color: '#E89B0D' }} className='uk-margin-small '>The email: {this.state.email} does not exist</p>}
                                        <div className=" uk-width-1-1@m">
                                            <button type='submit' className="uk-button register-btn uk-button-large" >
                                                {this.state.isProcessing
                                                    ? <div id='btnloader' className='uk-inline '>
                                                        <svg height="24" width="24" viewBox="0 0 24 24">
                                                            <circle opacity=".20" cx="12" cy="12" r="10"></circle><circle cx="12" cy="12" r="10" strokeDasharray="33"></circle>
                                                        </svg>

                                                    </div>
                                                    : 'Reset password'}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            }
                        </div>
                    </div>
                </div>

                <div className="uk-position-bottom bottom-bar">
                    <nav className="uk-navbar-container uk-navbar-transparent" data-uk-navbar>
                        <div className="uk-navbar-left">
                            <ul className="uk-navbar-nav footer-nav">
                                <li><a href="/">© YWAP 2024</a></li>
                            </ul>
                        </div>
                        <div className="uk-navbar-right">
                            <ul className="uk-navbar-nav footer-nav">
                                <li>
                                    <a href="https://www.instagram.com/ywaphq/" target='_blank' rel="noreferrer">Instagram</a>
                                </li>
                                <li>
                                    <a href="https://www.facebook.com/YWAPhq" target='_blank' rel="noreferrer">Facebook</a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/ywaphq" target='_blank' rel="noreferrer">YouTube</a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </section >
        )
    }
}
export default ForgotPassword
