import React from 'react';
import axios from 'axios';
import { Navigate } from "react-router-dom";
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import { API_URL } from '../../apiURL'
import logo from '../../assets/img/logo.svg'

class TableRow extends React.Component {
    render() {
        return (
            <tr>
                <td>{this.props.camper.user_id[0].name}</td>
                <td>{this.props.camper.user_id[0].email}</td>
                <td>{this.props.camper.user_id[0].phone}</td>
                <td>{this.props.camper.user_id[0].center}</td>
                <td>{this.props.camper.boothcamp_id}</td>
                <td>{this.props.camper.platoon}</td>
                <td>{this.props.camper.transaction_id}</td>
                <td>₦ {this.props.camper.amount}</td>
                {/* <td>₦7000</td> */}
            </tr>
        )
    }
}

class DonateRow extends React.Component {
    render() {
        return (
            <tr>
                <td>{this.props.donor.name}</td>
                <td>{this.props.donor.email}</td>
                <td>₦ {this.props.donor.amount - (this.props.donor.amount * 0.04)}</td>

            </tr>
        )
    }
}



class Stats extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    }
    constructor(props) {
        super(props);
        this.state = {
            notLoggedIn: false,
            isLoading: true,
            bcxviii: [],
            bio: [],
            totalCamper: 0,
            totalRegistered: 0,
            donate: []

        }
    }

    componentDidMount() {
        this.getBcxviii()
        this.getBio()
        this.getDonate()
        this.checkCookie();
        this.checkAdmin();

    }
    getBcxviii = () => {
        axios.get(API_URL + '/api/getbcxviii/')
            .then((response) => {
                this.setState({ bcxviii: response.data, totalRegistered: response.data.length, isLoading: false })
                // console.log(response)
            })
            .catch((e) => {
                if (e.response) {
                    console.log(e);
                }
            });
    }
    getDonate = () => {
        axios.get(API_URL + '/api/getgiving/')
            .then((response) => {
                this.setState({ donate: response.data, isLoading: false })
                console.log(response)
            })
            .catch((e) => {
                if (e.response) {
                    console.log(e);
                }
            });
    }

    getBio = () => {
        axios.get(API_URL + '/api/bio/')
            .then((response) => {
                this.setState({ bio: response.data, totalCamper: response.data.length, isLoading: false })
            })
            .catch((e) => {
                if (e.response) {
                    console.log(e);
                }
            });
    }

    checkCookie = () => {
        const { cookies } = this.props;
        if (cookies.get('token') === "" || cookies.get('token') === undefined) {
            this.setState({ notLoggedIn: true })
        }
    }

    checkAdmin = () => {
        const { cookies } = this.props;
        axios.get(API_URL + '/api/getuser/' + (cookies.get('token')))
            .then((response) => {
                if (response.data[0].user_type === 1) {
                    this.setState({ notAdmin: false })
                }
                else {
                    this.setState({ notAdmin: true })
                }
            })
            .catch((e) => {
                if (e.response) {
                    console.log(e);
                }
            });
    }
    // computeSum = (arr, totalFieldName) => {
    //     const { length } = arr;
    //     let count = 0;
    //     for (let i = 0; i < length; i += 1) {
    //         count += Number((typeof arr[i][totalFieldName] === 'object')
    //             ? arr[i][totalFieldName].name : arr[i][totalFieldName] || 0);
    //     }
    //     return count;
    // };

    computeTotal = (arr, totalFieldName) => {
        const { length } = arr;
        let count = 0;
        var amount
        for (let i = 0; i < length; i += 1) {
            if (arr[i][totalFieldName] === '15750') {
                amount = 15000
            }
            if (arr[i][totalFieldName] === '10500') {
                amount = 10000
            }
            count += amount;
        }
        return count;
    };

    render() {

        return (
            <div>
                {this.state.isLoading
                    ?
                    <div id='loader'>
                        <svg height="32" width="32" viewBox="0 0 24 24">
                            <circle opacity=".20" cx="12" cy="12" r="10"></circle><circle cx="12" cy="12" r="10" strokeDasharray="33"></circle>
                        </svg>
                    </div>
                    :
                    <section className="dark-section uk-position-relative" data-uk-height-viewport>
                        {this.state.notLoggedIn &&
                            <Navigate to="/login" replace={true} />
                        }
                         {this.state.notAdmin &&
                            <Navigate to="/dashboard" replace={true} />
                        }
                        <nav className="uk-navbar-container uk-navbar-transparent uk-visible@m" data-uk-navbar>
                            <div className="uk-navbar-left ">
                                <ul className="uk-navbar-nav">
                                    <li><a href="/admin">
                                        <img className='logo' src={logo} alt='logo' /> <span className='logo-text'>Boothcamp BCXVIII</span>
                                    </a></li>
                                </ul>
                            </div>
                            <div className="uk-navbar-right">
                                <ul className="uk-navbar-nav">

                                    <li><a href="/admin/stats" className="uk-button register-btn  uk-button-large">Admin Stats </a></li>
                                </ul>
                            </div>
                        </nav>
                        <div className='uk-hidden@m'>
                            <nav className="uk-navbar-container uk-navbar-transparent " data-uk-navbar>
                                <div className="uk-navbar-left ">
                                    <ul className="uk-navbar-nav">
                                        <li><a href="/dashboard">
                                            <img className='logo' src={logo} alt='logo' /> <span className='logo-text'>Boothcamp BCXVIII</span>
                                        </a></li>
                                    </ul>
                                </div>
                                <div className="uk-navbar-right">
                                    <ul className="uk-navbar-nav">

                                        <li><a href="/admin/stats" className="uk-button register-btn  uk-button-large">Admin Stats </a></li>
                                    </ul>
                                </div>
                            </nav>

                        </div>
                        <section className='dark-section reg-bg'>
                            <div className='uk-container login-container'>
                                <div className='uk-margin-xlarge-top'>
                                    <h1 className='reg-header uk-margin-medium '>Boothcamp BCXVIII Stats </h1>
                                    <div data-uk-grid>
                                        <div className='uk-width-1-2@m'>
                                            <div className="stats-card">
                                                <p>Total Registrations</p>
                                                <h1>{this.state.totalRegistered}</h1>
                                            </div>
                                        </div>
                                        <div className='uk-width-1-2@m'>
                                            <div className="stats-card">
                                                <p>Total Accounts Created</p>
                                                <h1>{this.state.totalCamper}</h1>
                                            </div>
                                        </div>
                                    </div>
                                    <ul data-uk-tab="connect: #login-form; animation: uk-animation-fade" className='uk-margin-large-top'>
                                        <li><a style={{ color: '#fff', fontSize: '24px', textTransform: 'none' }} href="#0">Registration</a></li>
                                        <li><a style={{ color: '#fff', fontSize: '24px', textTransform: 'none' }} href="#0">Donation</a></li>
                                    </ul>

                                    <ul id="login-form" className="uk-switcher uk-margin">
                                        <li>
                                            <div className='uk-margin-medium' data-uk-grid>
                                                <div className='uk-width-1-2@m'>
                                                    <h1 className='reg-header uk-margin-medium '>Registration Details  </h1>
                                                </div>
                                                <div className='uk-width-1-2@m'>
                                                    <h1 className='reg-header uk-margin-medium '>Total Amount : <span style={{ color: '#E89B0D' }}>₦{this.computeTotal(this.state.bcxviii, "amount").toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}  </span></h1>
                                                </div>
                                            </div>
                                            <div className="uk-overflow-auto">
                                                <table className="uk-table">
                                                    <thead>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Email</th>
                                                            <th>Phone</th>
                                                            <th>YWAP Center</th>
                                                            <th>Boothcamp ID</th>
                                                            <th>Platoon</th>
                                                            <th>Transaction ID</th>
                                                            <th>Amount Paid</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.bcxviii.map(camper =>
                                                            <TableRow key={camper._id}
                                                                index={camper._id}
                                                                camper={camper} />
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <p>Total: {this.state.totalRegistered}</p>
                                            <div className='uk-margin uk-margin-xlarge-bottom'>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='uk-margin-medium' data-uk-grid>
                                                <div className='uk-width-1-2@m'>
                                                    <h1 className='reg-header uk-margin-medium '>Donation Details  </h1>
                                                </div>
                                                <div className='uk-width-1-2@m'>
                                                    {/* <h1 className='reg-header uk-margin-medium '>Total Amount : <span style={{ color: '#E89B0D' }}>₦{(this.state.totalRegistered * 7000).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}  </span></h1> */}
                                                </div>
                                            </div>
                                            <div className="uk-overflow-auto">
                                                <table className="uk-table">
                                                    <thead>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Email</th>
                                                            <th>Amount</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.donate.map(donor =>
                                                            <DonateRow key={donor._id}
                                                                index={donor._id}
                                                                donor={donor} />
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className='uk-margin uk-margin-xlarge-bottom'>
                                            </div>
                                        </li>

                                    </ul>

                                    {/* <hr /> */}


                                </div>
                            </div>
                        </section>
                        <div className="bottom-bar">
                            <nav className="uk-navbar-container uk-navbar-transparent" data-uk-navbar>
                                <div className="uk-navbar-left">
                                    <ul className="uk-navbar-nav footer-nav">
                                        <li><a href="/">© YWAP 2023</a></li>
                                    </ul>
                                </div>
                                <div className="uk-navbar-right">
                                    <ul className="uk-navbar-nav footer-nav">
                                        <li>
                                            <a href="https://www.instagram.com/ywaphq/" target='_blank' rel="noreferrer">Instagram</a>
                                        </li>
                                        <li>
                                            <a href="https://www.facebook.com/YWAPhq" target='_blank' rel="noreferrer">Facebook</a>
                                        </li>
                                        <li>
                                            <a href="https://www.youtube.com/ywaphq" target='_blank' rel="noreferrer">YouTube</a>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </section>}
            </div>
        )
    }

}
export default withCookies(Stats)
