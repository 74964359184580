import React from 'react';
import axios from 'axios';
import { FlutterWaveButton, closePaymentModal } from 'flutterwave-react-v3';
import { API_URL } from '../apiURL'
import logo from '../assets/img/logo.svg'



class Donate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            amount: 0,
            isLoading: true,
            isCompleted: false,
            donate: []

        }
    }

    componentDidMount() {
        this.setState({ isLoading: false })
    }


    submitDonate = (e) => {
        e.preventDefault();
        console.log('Donate')
    }

    inputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    verifydonation = (resp) => {
        this.setState({ isLoading: true })
        axios.post(API_URL + '/api/verifydonation/', resp)
            .then((response) => {
                this.setState({ donate: response.data, isCompleted: true, isLoading: false })
            })
            .catch((e) => {
                if (e.response) {
                    console.log(e);
                }
            });

    }

    render() {
        const config = {
            public_key: 'FLWPUBK-458a89466adca3bc3400e1483675ced5-X',
            tx_ref: Date.now(),
            amount: this.state.amount,
            currency: 'NGN',
            subaccounts: [
                {
                    id: "RS_9BB18F69AE168DFB4CC2B3C53C6EB639",
                    transaction_charge_type: "flat_subaccount",
                    transaction_charge: this.state.amount - (this.state.amount * 0.04),
                }
            ],
            payment_options: 'card,ussd',
            customer: {
                email: this.state.email,
                phonenumber: '',
                name: this.state.name,
            },
            meta: {
                payment_type: 'donation'
            },
            customizations: {
                title: "YWAP Boothcamp BCXVII Donation",
                description: "Donate to YWAP Boothcamp",
                logo: "https://res.cloudinary.com/isabicontest/image/upload/v1652659707/YWAP/bc_logo_reux7u.svg",
            },
        };

        const fwConfig = {
            ...config,
            text: this.state.amount === 0 ? 'Donate' : 'Donate ₦' + this.state.amount,
            callback: (response) => {
                closePaymentModal()
                this.verifydonation(response)
            },
            onClose: (incomplete) => {
                // console.log('Close: ', incomplete)
            },
        };


        return (
            <div>
                {this.state.isLoading
                    ?
                    <div id='loader'>
                        <svg height="32" width="32" viewBox="0 0 24 24">
                            <circle opacity=".20" cx="12" cy="12" r="10"></circle><circle cx="12" cy="12" r="10" strokeDasharray="33"></circle>
                        </svg>
                    </div>
                    :
                    <section className="dark-section uk-position-relative" data-uk-height-viewport>
                        <nav className="uk-navbar-container uk-navbar-transparent " data-uk-navbar>
                            <div className="uk-navbar-left ">
                                <ul className="uk-navbar-nav">
                                    <li><a href="/">
                                        <img className='logo' src={logo} alt='logo' /> <span className='logo-text'>Boothcamp BCXVII</span>
                                    </a></li>
                                </ul>
                            </div>
                            <div className="uk-navbar-right">
                                <ul className="uk-navbar-nav">
                                    <li><a href="/register" className="uk-button register-btn  uk-button-large">register </a></li>
                                </ul>
                            </div>
                        </nav>
                        <section className='dark-section reg-bg'>
                            <div className='uk-container login-container'>
                                <div style={{ minHeight: '60vh' }} className='uk-margin-xlarge-top uk-margin-xlarge-bottom'>
                                    {this.state.isCompleted
                                        ?
                                        <div>
                                            <h1 className='reg-header-orange uk-margin-small-top'>Thank You!</h1>

                                            <h1 className='reg-header uk-margin-medium '>Hello {this.state.donate.name}  </h1>
                                            <h1 className='reg-header uk-margin-medium '>Thank you for Donating ₦{this.state.donate.amount} to Boothcamp BCXVII  </h1>
                                            <h1 className='reg-header uk-margin-medium '>The Success of YWAP is fueled by  the Generosity and commitment of people like you. </h1>
                                            <h1 className='reg-header uk-margin-medium '>God Bless you.</h1>
                                        </div>
                                        :
                                        <div>
                                            <h1 className='reg-header-orange uk-margin-small-top'>Donate to Boothcamp!</h1>
                                            <h1 className='reg-header uk-margin-medium '>The Success of YWAP is fueled by  the Generosity and commitment of people like you. <br /><br />Cheerfully give towards making boothcamp memorable and successful.</h1>

                                            <form method='POST' onSubmit={this.submitDonate} className="uk-margin-large-top" data-uk-grid>

                                                <div className=" uk-width-1-3@m">
                                                    <label className="form-label">Name</label>
                                                    <input name="name" className="uk-input uk-form-large uk-margin-small-top" type="text" placeholder="enter name" onChange={this.inputChange} required />
                                                </div>
                                                <div className=" uk-width-1-3@m">
                                                    <label className="form-label">Email</label>
                                                    <input name="email" className="uk-input uk-form-large uk-margin-small-top" type="email" placeholder="enter email" onChange={this.inputChange} required />
                                                </div>
                                                <div className=" uk-width-1-3@m">
                                                    <label className="form-label">Amount</label>
                                                    <input name="amount" className="uk-input uk-form-large uk-margin-small-top" type="number" placeholder="(₦) enter amount" onChange={this.inputChange} required />
                                                </div>
                                                <div className='uk-width-1-2@m'>
                                                    {this.state.amount > 0
                                                        ?
                                                        <FlutterWaveButton className="uk-button register-btn uk-button-large uk-margin-remove uk-width-1-2@m" {...fwConfig} />
                                                        :
                                                        <button type='submit' className="uk-button register-btn uk-button-large uk-margin-remove uk-width-1-2@m" >
                                                            Donate
                                                        </button>
                                                    }
                                                </div>
                                            </form>
                                        </div>
                                    }


                                </div>
                            </div>
                        </section>
                        <div className="bottom-bar">
                            <nav className="uk-navbar-container uk-navbar-transparent" data-uk-navbar>
                                <div className="uk-navbar-left">
                                    <ul className="uk-navbar-nav footer-nav">
                                        <li><a href="/">© YWAP 2023</a></li>
                                    </ul>
                                </div>
                                <div className="uk-navbar-right">
                                    <ul className="uk-navbar-nav footer-nav">
                                        <li>
                                            <a href="https://www.instagram.com/ywaphq/" target='_blank' rel="noreferrer">Instagram</a>
                                        </li>
                                        <li>
                                            <a href="https://www.facebook.com/YWAPhq" target='_blank' rel="noreferrer">Facebook</a>
                                        </li>
                                        <li>
                                            <a href="https://www.youtube.com/ywaphq" target='_blank' rel="noreferrer">YouTube</a>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </section>}
            </div>
        )
    }

}
export default Donate
