import React from 'react';
import axios from 'axios';
import { FlutterWaveButton, closePaymentModal } from 'flutterwave-react-v3';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import { Navigate } from "react-router-dom";
import { API_URL } from '../apiURL'
import logo from '../assets/img/logo.svg'



class RegDonate extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    }
    constructor(props) {
        super(props);
        this.state = {
            amount: 0,
            notLoggedIn: false,
            bcxviii: [],
            bio: [],
            loadDashboard: false,
            isLoading: true,
            isCompleted: false,
            donate: []


        }
    }

    componentDidMount() {
        this.checkCookie();
        this.getBcxviii();
    }
    getBcxviii = () => {
        const { cookies } = this.props;
        axios.get(API_URL + '/api/getbcxviii/' + (cookies.get('token')))
            .then((response) => {
                // console.log(response.data)
                if (response.data.length > 0) {
                    this.setState({ bcxviii: response.data[0], bio: response.data[0].user_id[0] })
                }
                else {
                    this.setState({ loadDashboard: true })
                }
                this.setState({ isLoading: false })
            })
            .catch((e) => {
                if (e.response) {
                    console.log(e);
                }
            });
    }

    checkCookie = () => {
        const { cookies } = this.props;
        if (cookies.get('token') === "" || cookies.get('token') === undefined) {
            this.setState({ notLoggedIn: true })
        }
    }

    submitDonate = (e) => {
        e.preventDefault();
        console.log('Donate')
    }

    inputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    verifydonation = (resp) => {
        this.setState({ isLoading: true })
        axios.post(API_URL + '/api/verifygiving/', resp)
            .then((response) => {
                this.setState({ donate: response.data, isCompleted: true, isLoading: false })
            })
            .catch((e) => {
                if (e.response) {
                    console.log(e);
                }
            });

    }

    render() {
        const config = {
            public_key: 'FLWPUBK-156b91580b16f2a7b51994d6adbad19b-X',
            tx_ref: Date.now(),
            amount: this.state.amount,
            currency: 'NGN',
            subaccounts: [
                {
                    id: "RS_98BEEE6B32FA3989C918C7FEFFF61C6A",
                    transaction_charge_type: "flat_subaccount",
                    transaction_charge: this.state.amount - (this.state.amount * 0.05),
                }
            ],
            payment_options: 'card,ussd',
            customer: {
                email: this.state.bio.email,
                phonenumber: this.state.bio.phone,
                name: this.state.bio.name,
            },
            meta: {
                user_id: this.state.bio._id,
                payment_type: 'donation'
            },
            customizations: {
                title: "YWAP Boothcamp BCXVIII Donation",
                description: "Donate to YWAP Boothcamp",
                logo: "https://res.cloudinary.com/ndirpaya/image/upload/v1714361920/ywap/ywap_bc_logo.svg",

            },
        };

        const fwConfig = {
            ...config,
            text: this.state.amount === 0 ? 'Donate' : 'Donate ₦' + this.state.amount,
            callback: (response) => {
                closePaymentModal()
                this.verifydonation(response)
            },
            onClose: (incomplete) => {
                // console.log('Close: ', incomplete)
            },
        };


        return (
            <div>
                {this.state.isLoading
                    ?
                    <div id='loader'>
                        <svg height="32" width="32" viewBox="0 0 24 24">
                            <circle opacity=".20" cx="12" cy="12" r="10"></circle><circle cx="12" cy="12" r="10" strokeDasharray="33"></circle>
                        </svg>
                    </div>
                    :
                    <section className="dark-section uk-position-relative" data-uk-height-viewport>
                        {this.state.notLoggedIn &&
                            <Navigate to="/login" replace={true} />
                        }
                        {this.state.loadDashboard &&
                            <Navigate to="/dashboard" replace={true} />
                        }
                        <nav className="uk-navbar-container uk-navbar-transparent uk-visible@m" data-uk-navbar>
                            <div className="uk-navbar-left ">
                                <ul className="uk-navbar-nav">
                                    <li><a href="/dashboard">
                                        <img className='logo' src={logo} alt='logo' /> <span className='logo-text'>Boothcamp BCXVIII</span>
                                    </a></li>
                                </ul>
                            </div>
                            <div className="uk-navbar-right">
                                <ul className="uk-navbar-nav">
                                    {!this.state.bcxviii && <li><a href="/dashboard" className="uk-button unpaid-btn  uk-button-large">Unpaid </a></li>}
                                    {this.state.bcxviii && <li><a href="/dashboard" className="uk-button paid-btn  uk-button-large">Paid </a></li>}
                                    <li><a href="/logout" className="uk-button register-btn  uk-button-large">log out </a></li>
                                </ul>
                            </div>
                        </nav>
                        <div className='uk-hidden@m'>
                            <nav className="uk-navbar-container uk-navbar-transparent " data-uk-navbar>
                                <div className="uk-navbar-left ">
                                    <ul className="uk-navbar-nav">
                                        <li><a href="/dashboard">
                                            <img className='logo' src={logo} alt='logo' /> <span className='logo-text'>Boothcamp BCXVIII</span>
                                        </a></li>
                                    </ul>
                                </div>
                            </nav>
                            <div className='uk-grid-collapse' data-uk-grid>
                                <div className='uk-width-3-5'>
                                    {!this.state.bcxviii && <a href='/dashboard' className="uk-button unpaid-btn uk-width-1-1 uk-button-large">Unpaid </a>}
                                    {this.state.bcxviii && <a href="/dashboard" className="uk-button paid-btn uk-width-1-1 uk-button-large">Paid </a>}
                                </div>
                                <div className='uk-width-2-5'>
                                    <li><a href="/logout" className="uk-button register-btn uk-width-1-1   uk-button-large">log out </a></li>
                                </div>
                            </div>
                        </div>
                        <section className='dark-section reg-bg'>
                            <div className='uk-container login-container'>
                                <div style={{ minHeight: '60vh' }} className='uk-margin-xlarge-top uk-margin-xlarge-bottom'>
                                    {this.state.isCompleted
                                        ?
                                        <div>
                                            <h1 className='reg-header uk-margin-medium '>Hello {this.state.bio.name}  </h1>
                                            <h1 className='reg-header uk-margin-medium '>Thank you for Donating ₦{this.state.donate.amount} to Boothcamp BCXVIII  </h1>
                                            <h1 className='reg-header uk-margin-medium '>The Success of YWAP is fueled by  the Generosity and commitment of people like you. </h1>
                                            <h1 className='reg-header uk-margin-medium '>God Bless you.</h1>
                                            <a href='/slip' className="uk-button register-btn uk-button-large uk-margin-remove">View Your Boothcamp Slip </a>
                                        </div>
                                        :
                                        <div>
                                            <h1 className='reg-header uk-margin-medium '>Hello {this.state.bio.name}  </h1>
                                            <h1 className='reg-header uk-margin-medium '>Thank you for Registering for Boothcamp BCXVIII  </h1>
                                            <h1 className='reg-header uk-margin-medium '>We'd like to ask you to Donate to Boothcamp BCXVIII before you proceed to view your registration slip</h1>

                                            <form method='POST' onSubmit={this.submitDonate} className="uk-margin-top" data-uk-grid>

                                                <div className=" uk-width-1-1@m">
                                                    <div className='uk-width-1-2@m'>
                                                        <label className="form-label">Amount</label>
                                                        <input name="amount" className="uk-input uk-form-large uk-margin-small-top" type="number" placeholder="(₦) enter amount" onChange={this.inputChange} required />
                                                    </div>
                                                </div>
                                                <div className='uk-width-1-2@m'>
                                                    {this.state.amount > 0
                                                        ?
                                                        <FlutterWaveButton className="uk-button register-btn uk-button-large uk-margin-remove uk-width-1-2@m" {...fwConfig} />
                                                        :
                                                        <button type='submit' className="uk-button register-btn uk-button-large uk-margin-remove uk-width-1-2@m" >
                                                            Donate
                                                        </button>
                                                    }
                                                </div>
                                            </form>

                                            <h1 className='reg-header-orange uk-margin-medium'>or</h1>
                                            <a href='/slip' className="uk-button register-btn uk-button-large uk-margin-remove">View Boothcamp Slip </a>
                                        </div>
                                    }


                                </div>
                            </div>
                        </section>
                        <div className="bottom-bar">
                            <nav className="uk-navbar-container uk-navbar-transparent" data-uk-navbar>
                                <div className="uk-navbar-left">
                                    <ul className="uk-navbar-nav footer-nav">
                                        <li><a href="/">© YWAP 2023</a></li>
                                    </ul>
                                </div>
                                <div className="uk-navbar-right">
                                    <ul className="uk-navbar-nav footer-nav">
                                        <li>
                                            <a href="https://www.instagram.com/ywaphq/" target='_blank' rel="noreferrer">Instagram</a>
                                        </li>
                                        <li>
                                            <a href="https://www.facebook.com/YWAPhq" target='_blank' rel="noreferrer">Facebook</a>
                                        </li>
                                        <li>
                                            <a href="https://www.youtube.com/ywaphq" target='_blank' rel="noreferrer">YouTube</a>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </section>}
            </div>
        )
    }

}
export default withCookies(RegDonate)
