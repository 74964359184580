import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { CookiesProvider } from 'react-cookie';
// eslint-disable-next-line
import UIkit from 'uikit'
// eslint-disable-next-line
import style from 'uikit/dist/css/uikit.css'
import './ywap.css';
import Home from './components/home';
import Question from './components/question';
import Error from './components/error';
import Register from './components/register';
import Login from './components/login';
import ForgotPassword from './components/forgot-password';
import ResetPassword from './components/reset-password';
import Dashboard from './components/dashboard';
import Slip from './components/slip';
import Badge from './components/badge';
import Logout from './components/logout';
import Donate from './components/donate';
import RegDonate from './components/reg-donate';
import Stats from './components/admin/stats';
import Admin from './components/admin/admin';
import Checkin from './components/admin/checkin';
import CheckinOffline from './components/admin/checkinoffline';
import AdminSlip from './components/admin/slip';
import AdminSlipOffline from './components/admin/slipoffline';
import ManualPayment from './components/manual-payment';
import reportWebVitals from './reportWebVitals';
import Checkinstats from './components/admin/checkinstats';
import Giving from './components/giving';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <CookiesProvider>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/reg-question" element={<Question />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
          <Route exact path="/reset-password/:id" element={<ResetPassword />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/slip" element={<Slip />} />
          <Route exact path="/badge" element={<Badge />} />
          <Route exact path="/logout" element={<Logout />} />
          <Route exact path="/reg-donate" element={<RegDonate />} />
          <Route exact path="/donate" element={<Donate />} />
          <Route exact path="/manual-payment" element={<ManualPayment />} />
          <Route exact path="/admin" element={<Admin />} />
          <Route exact path="/giving" element={<Giving />} />
          <Route exact path="/admin/check-in" element={<Checkin />} />
          <Route exact path="/admin/check-in-offline" element={<CheckinOffline />} />
          <Route exact path="/admin/stats" element={<Stats />} />
          <Route exact path="/admin/check-in-stats" element={<Checkinstats />} />
          <Route exact path="/admin/slip/:id" element={<AdminSlip />} />
          <Route exact path="/admin/slip-offline/:id" element={<AdminSlipOffline />} />
          <Route exact path="*" element={<Error />} />
        </Routes>
      </BrowserRouter>
    </CookiesProvider>
  </React.StrictMode>
);

reportWebVitals();
