import React from 'react';
import axios from 'axios';
import { Navigate } from "react-router-dom";
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import { API_URL } from '../../apiURL'
import logo from '../../assets/img/logo.svg'


class TableRow extends React.Component {
    render() {
        // console.log(this.props.camper.user_id[0]._id)
        return (
            <tr>
                <td>{this.props.camper.user_id[0].name}</td>
                <td>{this.props.camper.user_id[0].email}</td>
                <td>{this.props.camper.user_id[0].phone}</td>
                {/* <td>{this.props.camper.user_id[0].center}</td> */}
                <td>{this.props.camper.boothcamp_id}</td>
                <td>{this.props.camper.platoon}</td>
                <td>{this.props.camper.transaction_id}</td>
                <td>₦ {this.props.camper.amount}</td>
                <td><a href={'/admin/slip/' + this.props.camper.user_id[0]._id} style={{ backgroundColor: '#E89B0D', color: '#000' }} className="uk-button uk-button-small">View</a></td>
                {/* <td>₦7000</td> */}
            </tr>
        )
    }
}

class SearchForm extends React.Component {
    handleInputChange = (e) => {
        const searchQuery = e.target.value;
        this.props.onSearch(searchQuery);
    };

    render() {
        return (
            <form>
                <div className=" uk-width-1-1@m">
                    <input name="name" className="uk-input uk-form-large uk-margin-small-top" type="text" placeholder="Enter Camper Name" onChange={this.handleInputChange} required />
                </div>

            </form>
        );
    }
}

class Checkin extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    }
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            notLoggedIn: false,
            notAdmin: false,
            bcxvii: [],
            filteredBcxvii: [],


        }
    }
    componentDidMount() {
        this.setState({ isLoading: false })
        this.checkCookie();
        this.checkAdmin();
        this.getBcxvii();
    }

    checkCookie = () => {
        const { cookies } = this.props;
        if (cookies.get('token') === "" || cookies.get('token') === undefined) {
            this.setState({ notLoggedIn: true })
        }
    }

    checkAdmin = () => {
        const { cookies } = this.props;
        axios.get(API_URL + '/api/getuser/' + (cookies.get('token')))
            .then((response) => {
                if (response.data[0].user_type === 1) {
                    this.setState({ notAdmin: false })
                }
                else {
                    this.setState({ notAdmin: true })
                }
            })
            .catch((e) => {
                if (e.response) {
                    console.log(e);
                }
            });
    }

    getBcxvii = () => {
        axios.get(API_URL + '/api/getnotcheckedin/')
            .then((response) => {
                this.setState({ filteredBcxvii: response.data, bcxvii: response.data, totalRegistered: response.data.length, isLoading: false })
                // console.log(response)
            })
            .catch((e) => {
                if (e.response) {
                    console.log(e);
                }
            });
    }
    

    handleSearch = (searchQuery) => {
        const filteredBcxvii = this.state.bcxvii.filter((user) =>
            user.user_id[0].name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        this.setState({ filteredBcxvii,totalRegistered: filteredBcxvii.length });
    };

    render() {
        const { filteredBcxvii } = this.state;
        return (
            <div>
                {this.state.isLoading
                    ?
                    <div id='loader'>
                        <svg height="32" width="32" viewBox="0 0 24 24">
                            <circle opacity=".20" cx="12" cy="12" r="10"></circle><circle cx="12" cy="12" r="10" strokeDasharray="33"></circle>
                        </svg>
                    </div>
                    :
                    <div>
                        {this.state.notLoggedIn &&
                            <Navigate to="/login" replace={true} />
                        }
                        {this.state.notAdmin &&
                            <Navigate to="/dashboard" replace={true} />
                        }
                        
                        <section className="dark-section uk-position-relative" data-uk-height-viewport>
                            <nav className="uk-navbar-container uk-navbar-transparent" data-uk-navbar>
                                <div className="uk-navbar-left ">
                                    <ul className="uk-navbar-nav">
                                        <li><a href="/admin">
                                            <img className='logo' src={logo} alt='logo' /> <span className='logo-text'>Boothcamp BCXVII</span>
                                        </a></li>
                                    </ul>
                                </div>
                            </nav>
                            <section className='dark-section reg-bg'>
                                <div className='uk-container login-container'>
                                    <div className='uk-margin-xlarge-top'>
                                        <h1 className='reg-header uk-margin-small '>Search for Camper</h1>
                                        <p>Total: {this.state.totalRegistered}</p>

                                        <SearchForm onSearch={this.handleSearch} />
                                        <div className="uk-overflow-auto">
                                            <table className="uk-table">
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Email</th>
                                                        <th>Phone</th>
                                                        {/* <th>YWAP Center</th> */}
                                                        <th>Boothcamp ID</th>
                                                        <th>Platoon</th>
                                                        <th>Transaction ID</th>
                                                        <th>Amount Paid</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredBcxvii.map(camper =>
                                                        <TableRow key={camper._id}
                                                            index={camper._id}
                                                            camper={camper} />
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </section>
                    </div>
                }
            </div>
        )
    }

}

export default withCookies(Checkin)
