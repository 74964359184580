import React from 'react';
import axios from 'axios';
import { API_URL } from '../apiURL'


import logo from '../assets/img/logo.svg'


class Register extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            dob: '',
            gender: '',
            phone: '',
            center: '',
            current_center: '',
            ywap_name: '',
            ywap_role: '',
            kin_phone: '',
            attend_boothcamp: 'No',
            medical_record: '',
            address: '',
            password: '',
            confirm_password: '',
            isProcessing: false,
            isCreated: false,
            emailExists: false,
            passwordError: false,
            bio: []
        }
    }

    inputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    createAccount = (e) => {
        e.preventDefault();
        this.setState({ isProcessing: true })
        const payload = {
            name: this.state.name,
            email: this.state.email,
            dob: this.state.dob,
            gender: this.state.gender,
            phone: this.state.phone,
            center: this.state.center,
            current_center: this.state.current_center,
            ywap_name: this.state.ywap_name,
            ywap_role: this.state.ywap_role,
            kin_phone: this.state.kin_phone,
            medical_record: this.state.medical_record,
            address: this.state.address,
            password: this.state.password,
        };

        if (this.state.password !== this.state.confirm_password) {
            this.setState({ passwordError: true, isProcessing: false })
        }
        else {
            this.setState({ passwordError: false, isProcessing: true })
            axios.post(API_URL + '/api/createbio', payload)
                .then((response) => {
                    this.setState({ isCreated: true, bio: response.data, isProcessing: false  })
                })
                .catch((e) => {
                    if (e.response) {
                        if (e.response.status === 409) {
                            this.setState({ emailExists: true })
                        }
                        console.log(e)
                    }
                });
        }


    }

    render() {
        // console.log(this.state.emailExists || this.state.isCreated)
        return (
            <div>
                <section className='dark-section reg-bg'>
                    <div className='uk-container'>
                        <div className="uk-text-center" data-uk-grid>
                            <div className="uk-width-expand@m">
                            </div>
                            <div className="uk-width-2-3@m  uk-margin-large-top uk-margin-bottom">
                                <div>
                                    <a href='/'>
                                        <img className='logo-reg' src={logo} alt='logo' />
                                        <span className="uk-text-middle reg-logo">Boothcamp BCXVIII</span>
                                    </a>
                                </div>
                            </div>
                            <div className="uk-width-expand@m">
                            </div>
                        </div>
                        {!(this.state.emailExists || this.state.isCreated) && <div className="alert" data-uk-alert>
                            <button className="uk-alert-close" data-uk-close></button>
                            <h5>Please Fill out the Form Carefully and Correctly as this will be your Official YWAP  Boothcamp Account!</h5>
                        </div>}
                        <div className='uk-container '>
                            <div className='reg-container uk-padding'>
                                {this.state.isCreated
                                    &&
                                    <div style={{ minHeight: '60vh' }}>
                                        <h1 className="reg-header uk-margin-top uk-margin-bottom">Hey <span style={{ color: '#E89B0D' }}>{this.state.bio.name}</span>,<br /><br /> Your YWAP Account is Created</h1>
                                        <p>Thank you {this.state.bio.name} for Creating a YWAP Boothcamp Account</p>
                                        <p>Kindly Proceed to login to continue your registration and make payment for Boothcamp BCXVIII</p>
                                        <a href="/login" className="uk-button register-btn uk-button-large uk-margin-medium-top">log in</a>
                                    </div>
                                }
                                {this.state.emailExists
                                    &&
                                    <div style={{ minHeight: '60vh' }}>
                                        <h1 className="reg-header uk-margin-top uk-margin-bottom">Email <span style={{ color: '#E89B0D' }}>{this.state.email}</span> Exists</h1>
                                        <p>The Email Address: {this.state.email} already exists on our Platform</p>
                                        <p>Kindly Restart your registration by clicking the button below</p>
                                        <a href="/register" className="uk-button register-btn uk-button-large uk-margin-medium-top">restart registration</a>
                                    </div>
                                }
                                {!(this.state.emailExists || this.state.isCreated) &&
                                    <div>
                                        <h1 className='reg-header'>We're excited to have you join us at Boothcamp BCXVIII.</h1>
                                        <form method='POST' onSubmit={this.createAccount} className="uk-margin-top" data-uk-grid>
                                            <div className=" uk-width-1-1@m">
                                                <h1 className='reg-header-orange uk-margin-small-top'>Personal Information</h1>
                                            </div>
                                            <div className=" uk-width-1-2@m">
                                                <label className="form-label">Name</label>
                                                <input name="name" className="uk-input uk-form-large uk-margin-small-top" type="text" placeholder="enter your full name" onChange={this.inputChange} required />
                                            </div>
                                            <div className=" uk-width-1-2@m">
                                                <label className="form-label">Email Address</label>
                                                <input name="email" className="uk-input uk-form-large uk-margin-small-top" type="email" placeholder="enter your email" onChange={this.inputChange} required />
                                            </div>
                                            <div className=" uk-width-1-2@m">
                                                <label className="form-label">Phone Number</label>
                                                <input name="phone" className="uk-input uk-form-large uk-margin-small-top" type="number" placeholder="080XXXXXXXX" onChange={this.inputChange} required />
                                            </div>
                                            <div className=" uk-width-1-2@m">
                                                <label className="form-label">Date of Birth</label>
                                                <input name="dob" className="uk-input uk-form-large uk-margin-small-top" type="date" placeholder="dd / mm / yy" onChange={this.inputChange} required />
                                            </div>
                                            <div className=" uk-width-1-2@m">
                                                <label className="form-label">YWAP Center</label>
                                                <select className="uk-select bc_select uk-margin-small-top" name='center' onChange={this.inputChange} required>
                                                    <option defaultValue value="">--Select a Center --</option>
                                                    <option value="Not a Member">Not a Member</option>
                                                    <option value="Abeokuta">Abeokuta</option>
                                                    <option value="Abuja">Abuja</option>
                                                    <option value="Akungba">Akungba</option>
                                                    <option value="Akwanga">Akwanga</option>
                                                    <option value="Ayingba">Ayingba</option>
                                                    <option value="Akwa Ibom">Akwa Ibom</option>
                                                    <option value="Abraka">Abraka</option>
                                                    <option value="Ayingba">Ayingba</option>
                                                    <option value="Bauchi">Bauchi</option>
                                                    <option value="Bida">Bida</option>
                                                    <option value="Calabar">Calabar</option>
                                                    <option value="Enugu">Enugu</option>
                                                    <option value="Gboko">Gboko</option>
                                                    <option value="Gombe">Gombe</option>
                                                    <option value="Gwagwalada">Gwagwalada</option>
                                                    <option value="Ibadan">Ibadan</option>
                                                    <option value="Ilorin">Ilorin</option>
                                                    <option value="Jigawa">Jigawa</option>
                                                    <option value="Jos">Jos</option>
                                                    <option value="Kaduna">Kaduna</option>
                                                    <option value="Keffi">Keffi</option>
                                                    <option value="Lafia">Lafia</option>
                                                    <option value="Lagos">Lagos</option>
                                                    <option value="Lokoja">Lokoja</option>
                                                    <option value="Maiduguri">Maiduguri</option>
                                                    <option value="Makurdi">Makurdi</option>
                                                    <option value="Maraba">Maraba</option>
                                                    <option value="Minna">Minna</option>
                                                    <option value="Nsukka">Nsukka</option>
                                                    <option value="Otukpo">Otukpo</option>
                                                    <option value="Owerri">Owerri</option>
                                                    <option value="Pankshin">Pankshin</option>
                                                    <option value="Port Harcourt">Port Harcourt</option>
                                                    <option value="Salem University">Salem University</option>
                                                    <option value="Taraba">Taraba</option>
                                                    <option value="Tarka">Tarka</option>
                                                    <option value="Uni Agric Makurdi">Uni Agric Makurdi</option>
                                                    <option value="Wukari">Wukari</option>
                                                    <option value="Yola">Yola</option>
                                                    <option value="Zaria">Zaria</option>
                                                </select>
                                                <p className='reg_small_text'>Your First Center when you Joined YWAP</p>
                                            </div>
                                            <div className=" uk-width-1-2@m">
                                                <label className="form-label">Current YWAP Center</label>
                                                <select className="uk-select bc_select uk-margin-small-top" name='current_center' onChange={this.inputChange} required>
                                                    <option defaultValue value="">--Select a Center --</option>
                                                    <option value="Not a Member">Not a Member</option>
                                                    <option value="Abeokuta">Abeokuta</option>
                                                    <option value="Abuja">Abuja</option>
                                                    <option value="Akungba">Akungba</option>
                                                    <option value="Akwanga">Akwanga</option>
                                                    <option value="Ayingba">Ayingba</option>
                                                    <option value="Akwa Ibom">Akwa Ibom</option>
                                                    <option value="Abraka">Abraka</option>
                                                    <option value="Ayingba">Ayingba</option>
                                                    <option value="Bauchi">Bauchi</option>
                                                    <option value="Bida">Bida</option>
                                                    <option value="Calabar">Calabar</option>
                                                    <option value="Enugu">Enugu</option>
                                                    <option value="Gboko">Gboko</option>
                                                    <option value="Gombe">Gombe</option>
                                                    <option value="Gwagwalada">Gwagwalada</option>
                                                    <option value="Ilorin">Ilorin</option>
                                                    <option value="Jigawa">Jigawa</option>
                                                    <option value="Jos">Jos</option>
                                                    <option value="Kaduna">Kaduna</option>
                                                    <option value="Keffi">Keffi</option>
                                                    <option value="Lafia">Lafia</option>
                                                    <option value="Lagos">Lagos</option>
                                                    <option value="Lokoja">Lokoja</option>
                                                    <option value="Maiduguri">Maiduguri</option>
                                                    <option value="Makurdi">Makurdi</option>
                                                    <option value="Maraba">Maraba</option>
                                                    <option value="Minna">Minna</option>
                                                    <option value="Nsukka">Nsukka</option>
                                                    <option value="Otukpo">Otukpo</option>
                                                    <option value="Owerri">Owerri</option>
                                                    <option value="Pankshin">Pankshin</option>
                                                    <option value="Port Harcourt">Port Harcourt</option>
                                                    <option value="Salem University">Salem University</option>
                                                    <option value="Taraba">Taraba</option>
                                                    <option value="Tarka">Tarka</option>
                                                    <option value="Uni Agric Makurdi">Uni Agric Makurdi</option>
                                                    <option value="Wukari">Wukari</option>
                                                    <option value="Yola">Yola</option>
                                                    <option value="Zaria">Zaria</option>
                                                </select>
                                            </div>
                                            <div className=" uk-width-1-2@m">
                                                <label className="form-label">YWAP Name</label>
                                                <input name="ywap_name" className="uk-input uk-form-large uk-margin-small-top" type="text" placeholder="eg. Luo, Don G, Fist" onChange={this.inputChange} required />
                                            </div>
                                            <div className=" uk-width-1-2@m">
                                                <label className="form-label">YWAP Role</label>
                                                <input name="ywap_role" className="uk-input uk-form-large uk-margin-small-top" type="text" placeholder="Coordinator, Member etc" onChange={this.inputChange} required />
                                            </div>
                                            <div className=" uk-width-1-2@m">
                                                <label className="form-label">Next of Kin Phone Number</label>
                                                <input name="kin_phone" className="uk-input uk-form-large uk-margin-small-top" type="tel" placeholder="080XXXXXXXX" onChange={this.inputChange} required />
                                            </div>
                                            <div className=" uk-width-1-2@m">
                                                <label className="form-label">Do you have a medical record</label>
                                                <input name="medical_record" className="uk-input uk-form-large uk-margin-small-top" type="text" placeholder="Please enter the exact medical record" onChange={this.inputChange} required />
                                            </div>
                                            <div className=" uk-width-1-2@m">
                                                <label className="form-label">Gender</label>
                                                <select className="uk-select bc_select uk-margin-small-top" name='gender' onChange={this.inputChange} required>
                                                    <option defaultValue value="">--Select An Answer --</option>
                                                    <option value="Male">Male</option>
                                                    <option value="Female">Female</option>
                                                </select>
                                            </div>
                                            <div className=" uk-width-1-1@m">
                                                <label className="form-label">Contact Address</label>
                                                <textarea className="uk-textarea uk-margin-small-top" rows="5" placeholder="" name='address' onChange={this.inputChange} required>
                                                </textarea>
                                            </div>
                                            <div className=" uk-width-1-1@m">
                                                <h1 className='reg-header-orange uk-margin-small-top'>Security Information</h1>
                                            </div>
                                            <div className=" uk-width-1-2@m">
                                                <label className="form-label">Password</label>
                                                <input name="password" className="uk-input uk-form-large uk-margin-small-top" type="password" placeholder="• • • • • • • •" onChange={this.inputChange} required />
                                            </div>
                                            <div className=" uk-width-1-2@m">
                                                <label className="form-label">Confirm Password</label>
                                                <input name="confirm_password" className="uk-input uk-form-large uk-margin-small-top" type="password" placeholder="• • • • • • • •" onChange={this.inputChange} required />
                                            </div>
                                            {this.state.passwordError &&
                                                <div className=" uk-width-1-1@m">
                                                    <p style={{ color: '#E89B0D' }} className='uk-margin-small '>Passwords Entered do Not Match. Please Check your input!</p>
                                                </div>
                                            }
                                            <div>
                                                <button type='submit' className="uk-button register-btn uk-button-large" >
                                                    {this.state.isProcessing
                                                        ? <div id='btnloader' className='uk-inline uk-padding-large uk-padding-remove-vertical'>
                                                            <svg height="24" width="24" viewBox="0 0 24 24">
                                                                <circle opacity=".20" cx="12" cy="12" r="10"></circle><circle cx="12" cy="12" r="10" strokeDasharray="33"></circle>
                                                            </svg>

                                                        </div>
                                                        : 'create account'}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                }
                            </div>

                        </div>
                    </div>
                    <div className="uk-margin-large-top bottom-bar">
                        <nav className="uk-navbar-container uk-navbar-transparent" data-uk-navbar>
                            <div className="uk-navbar-left">
                                <ul className="uk-navbar-nav footer-nav">
                                    <li><a href="/">© YWAP 2024</a></li>
                                </ul>
                            </div>
                            <div className="uk-navbar-right">
                                <ul className="uk-navbar-nav footer-nav">
                                    <li>
                                        <a href="https://www.instagram.com/ywaphq/" target='_blank' rel="noreferrer">Instagram</a>
                                    </li>
                                    <li>
                                        <a href="https://www.facebook.com/YWAPhq" target='_blank' rel="noreferrer">Facebook</a>
                                    </li>
                                    <li>
                                        <a href="https://www.youtube.com/ywaphq" target='_blank' rel="noreferrer">YouTube</a>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </section>
            </div>
        )
    }

}
export default Register