import React from 'react';
import axios from 'axios';
import { API_URL } from '../apiURL'
import logo from '../assets/img/logo.svg'
import bg from '../assets/img/mountain.svg'
var token = (window.location.href).split('/').pop();

class ResetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            confirm_password: '',
            isProcessing: false,
            isCompleted: false,
            passwordError: false,
        }
    }

    inputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    resetPassword = (e) => {
        e.preventDefault();
        this.setState({ isProcessing: true })

        if (this.state.password !== this.state.confirm_password) {
            this.setState({ passwordError: true, isProcessing: false })
        }
        else {
            this.setState({ passwordError: false, isProcessing: true })
            const payload = {
                password: this.state.password
            }

            axios.put(API_URL + '/api/resetpassword/' + token, payload)
                .then((response) => {
                    if (response.status === 201) {
                        this.setState({ isProcessing: false, isCompleted: true })
                    }
                })
                .catch((e) => {
                    if (e.response) {
                        console.log(e);
                    }
                });

        }


    }

    render() {
        return (
            <section className="dark-section uk-position-relative" data-uk-height-viewport>
                <nav className="uk-navbar-container uk-navbar-transparent uk-position-top" data-uk-navbar>
                    <div className="uk-navbar-left">
                        <ul className="uk-navbar-nav">
                            <li><a href="/">
                                <img className='logo' src={logo} alt='logo' /> <span className='logo-text'>Boothcamp BCXVIII</span>
                            </a></li>
                        </ul>
                    </div>
                </nav>
                <div className="uk-flex uk-flex-center uk-flex-middle uk-background-cover" data-src={bg} data-uk-height-viewport data-uk-img="loading: eager">

                    <div className='uk-text-left uk-width-2-5@m'>

                        <div className='login-container uk-container-xlarge uk-padding-large'>
                            {this.state.isCompleted
                                ?
                                <div>
                                    <h1 className="reg-header uk-margin-top uk-margin-bottom">Reset Successful!</h1>
                                    <p>Your Password has been reset successfully</p>
                                    <p className=' uk-margin-large-top' style={{ color: '#FFFFFF' }}>Proceed to Login to Your Dashboard</p>

                                    <a href="/login" className="uk-button register-btn uk-button-large uk-margin-top">log in</a>

                                </div>
                                :
                                <div>
                                    <h1 className='reg-header'>Reset Your Password </h1>
                                    <p>Enter Your New Password.</p>
                                    <form method='POST' onSubmit={this.resetPassword} className="uk-margin-top" data-uk-grid>
                                        <div className=" uk-width-1-1@m uk-margin-top">
                                            <label className="form-label">New Password</label>
                                            <input name="password" className="uk-input uk-form-large uk-margin-small-top" type="password" placeholder="• • • • • • • •" onChange={this.inputChange} required />
                                        </div>
                                        <div className=" uk-width-1-1@m uk-margin-top">
                                            <label className="form-label">confirm Password</label>
                                            <input name="confirm_password" className="uk-input uk-form-large uk-margin-small-top" type="password" placeholder="• • • • • • • •" onChange={this.inputChange} required />
                                        </div>
                                        {this.state.passwordError &&
                                            <p style={{ color: '#E89B0D' }} className='uk-margin-small '>Passwords Entered do Not Match. Please Check your input!</p>}
                                        <div className=" uk-width-1-1@m">
                                            <button type='submit' className="uk-button register-btn uk-button-large" >
                                                {this.state.isProcessing
                                                    ? <div id='btnloader' className='uk-inline '>
                                                        <svg height="24" width="24" viewBox="0 0 24 24">
                                                            <circle opacity=".20" cx="12" cy="12" r="10"></circle><circle cx="12" cy="12" r="10" strokeDasharray="33"></circle>
                                                        </svg>

                                                    </div>
                                                    : 'Reset password'}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            }
                        </div>
                    </div>
                </div>

                <div className="uk-position-bottom bottom-bar">
                    <nav className="uk-navbar-container uk-navbar-transparent" data-uk-navbar>
                        <div className="uk-navbar-left">
                            <ul className="uk-navbar-nav footer-nav">
                                <li><a href="/">© YWAP 2024</a></li>
                            </ul>
                        </div>
                        <div className="uk-navbar-right">
                            <ul className="uk-navbar-nav footer-nav">
                                <li>
                                    <a href="https://www.instagram.com/ywaphq/" target='_blank' rel="noreferrer">Instagram</a>
                                </li>
                                <li>
                                    <a href="https://www.facebook.com/YWAPhq" target='_blank' rel="noreferrer">Facebook</a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/ywaphq" target='_blank' rel="noreferrer">YouTube</a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </section >
        )
    }
}
export default ResetPassword
